var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-row address-card-custom"
  }, [_c('div', {
    staticClass: "flex-row-auto w-300px w-xl-350px",
    attrs: {
      "id": "kt_source_aside"
    }
  }, [_c('div', {
    staticClass: "card card-custom card-stretch"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "navi navi-bold navi-hover navi-active navi-link-rounded",
    attrs: {
      "role": "tablist"
    }
  }, [_vm._l(_vm.componentList, function (comp, idx) {
    return [comp.canShow ? _c('div', {
      key: idx,
      staticClass: "navi-item mb-2"
    }, [_c(comp.isDisabled ? 'span' : 'router-link', {
      tag: "router-link",
      staticClass: "navi-link py-4",
      class: {
        'cursor-not-allowed': comp.isDisabled,
        'cursor-pointer': !comp.isDisabled,
        disabled: comp.isDisabled
      },
      attrs: {
        "active-class": "active",
        "data-tab": idx,
        "data-toggle": "tab",
        "role": "tab",
        "aria-selected": "false",
        "to": {
          name: comp.routeName
        }
      }
    }, [_c('span', {
      staticClass: "navi-icon"
    }, [_c('i', {
      class: comp.icon
    })]), _c('span', {
      staticClass: "navi-text font-size-lg"
    }, [_vm._v(_vm._s(comp.name))])])], 1) : _vm._e()];
  })], 2)])])]), _c('div', {
    staticClass: "flex-row-fluid ml-lg-8"
  }, [_c('div', {
    staticClass: "card card-custom"
  }, [_c('router-view', _vm._g(_vm._b({
    key: _vm.$route.fullPath
  }, 'router-view', _vm.currentDetailComponentProps, false), _vm.currentDetailComponentEvents))], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }