<template>
  <div class="d-flex flex-row address-card-custom">
    <div id="kt_source_aside" class="flex-row-auto w-300px w-xl-350px">
      <div class="card card-custom card-stretch">
        <div class="card-body">
          <div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">
            <template v-for="(comp, idx) in componentList">
              <div v-if="comp.canShow" :key="idx" class="navi-item mb-2">
                <router-link :is="comp.isDisabled ? 'span' : 'router-link'" active-class="active" class="navi-link py-4"
                  :class="{
                    'cursor-not-allowed': comp.isDisabled,
                    'cursor-pointer': !comp.isDisabled,
                    disabled: comp.isDisabled,
                  }" :data-tab="idx" data-toggle="tab" role="tab" aria-selected="false" :to="{ name: comp.routeName }">
                  <span class="navi-icon">
                    <i :class="comp.icon"></i>
                  </span>
                  <span class="navi-text font-size-lg">{{ comp.name }}</span>
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-row-fluid ml-lg-8">
      <div class="card card-custom">
        <router-view :key="$route.fullPath" v-bind="currentDetailComponentProps"
          v-on="currentDetailComponentEvents"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import icons from "@/core/config/icons.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  data() {
    return {
      icons,
    }
  },
  computed: {
    currentDetailComponentProps() {
      return this.currentDetail?.props;
    },

    currentDetailComponentEvents() {
      return this.currentDetail?.events;
    },

    currentDetail() {
      return this.componentList.find((item) => item.routeName == this.$route.name);
    },

    componentList() {
      return [
        {
          routeName: "source-list",
          name: this.$t("Sources"),
          icon: icons.list,
          canShow: true,
        },
        {
          routeName: "post-list",
          name: this.$t("Post"),
          icon: icons.clone,
          canShow: true,
        }
      ]
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Administration") },
      { title: this.$t("Pubblications") }
    ]);
  },
};
</script>

